import { types } from "api/gen";
import { localTheme } from "./theme";
import { SensorName, SensorType } from "./constants";
// this file should be moved to dataflow-core
export const riskyColors = {
  low_risky: localTheme.yellow,
  risky: localTheme.red,
  risky_or_low: localTheme.red,
  needs_review: localTheme.gray2,
  approved: localTheme.green,
} as Record<types.CategoryStatus, string>;

export function getColorForRisk(r: types.CategoryStatus) {
  return riskyColors[r] ?? riskyColors.needs_review;
}

export const getSensorNames = (sensorTypes: SensorType[]): SensorName[] => {
  return Object.values(SensorName).filter((name) =>
    sensorTypes.some((sensorType) => name.startsWith(sensorType))
  );
};
