import { getOffset } from "libs/time";
import timezonesListJson from "./timezones.json";

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const timezonesList = timezonesListJson
  .filter(({ showInList, tzCode }) => showInList || tzCode === localTimezone)
  .map((tz) => {
    try {
      const offsetInMinutes = getOffset(tz.tzCode);
      const plus = offsetInMinutes >= 0;
      const offsetInHours = Math.abs(offsetInMinutes / 60);
      const fullHours = Math.floor(offsetInHours);
      const hoursString = ("00" + fullHours).slice(-2);
      const floatHours = offsetInHours - fullHours;
      const minutesString = ("00" + floatHours * 60).slice(-2);

      return {
        ...tz,
        offsetInMinutes,
        utc: `${plus ? "+" : "-"}${hoursString}:${minutesString}`,
      };
    } catch {
      // normally this should not happen, js supports all timezones that we are currently using
      console.error("some of timezone codes in timezones.json file could obsolete");
      const [utcHours, utcMinutes] = tz.utc.slice(1).split(":");
      return {
        offsetInMinutes: Number(utcHours) * 60 + Number(utcMinutes),
        ...tz,
      };
    }
  })
  .sort((tzA, tzB) => tzA.offsetInMinutes - tzB.offsetInMinutes);
