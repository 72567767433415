import { ReactElement } from "react";
import { groupBy, mapValues, upperFirst } from "lodash";
import { PermissionCheck } from "shared/permissionsTypes";
import { ENV } from "runenv";
import { types } from "api";
import { SearchFieldIsUsableInPolicies, types_SearchFieldEnum } from "api/gen";
import { CATEGORY_TYPES, MEDIA_CATEGORY_TYPES } from "modules/graph-search-panel/constants";
import {
  EventTypesMap,
  getIcon,
  getUniqEvents,
  locationCategoryOptions,
} from "modules/dataflow-core";
import { compareByProp } from "libs/data-utils";
import { CLOUD_APP_TYPES } from "modules/dataflow-core/cloud-apps";
import { AllFieldNamesEnum, FullOperatorEnum } from "api/types";
import { Operator } from "../operators/operatorTypes";
import { locations } from "../shared/locations";
import { SearchFieldValue, UserColumnField } from "../users-table/types";
import {
  cloudAccessLevelOptions,
  cloudSharedTypeCategoryOptions,
} from "../../dataflow-core/cloud-shared-type-category";

export type FieldName = Exclude<
  types.SearchField,
  "keywords" | "cloud_provider" | "browser_page_url" | "sensor_name"
>;

export type FieldsConfig = Record<FieldName, ConfigField>;
export type FieldsGroups =
  | "location"
  | "event"
  | "user"
  | "content"
  | "directory"
  | "dataset"
  | "lists"
  | "policy";
export enum ConfigFieldScope {
  IRM = "irm",
  RISK_OVERVIEW = "risk_overview",
  RISK_OVERVIEW_DIRECTORY = "risk_overview_directory",
}
export type ConfigField = {
  title: string;
  info: string;
  key?: string;
  data?: {
    showForOrigin?: boolean;
    showForPolicy?: boolean;
    group: FieldsGroups;
    type?: string;
    enumType?: string;
    options?: any;
    operators?: Operator[];
    isPluralOperators?: boolean;
    parentNotSelectable?: boolean;
    defaultExpandedKeys?: string[];
  };
  scope: ConfigFieldScope[];
  icon?: ReactElement;
  icons?: ReactElement[];
  requiredPermissions?: PermissionCheck;
  deprecationMessage?: string;
};

const SearchFieldIsVisibleForDataset = new Map(
  Object.entries({
    event_type: false,
    cloud_shared_role: false,
    cloud_shared_type: false,
    cloud_shared_with: false,
    cloud_destination_groups: false,
  } as Partial<Record<types.SearchField, boolean>>)
);
const SearchFieldIsVisibleForPolicy = new Map(
  Object.entries({
    ...SearchFieldIsUsableInPolicies,
  } as Partial<Record<types.SearchField, boolean>>)
);

function getIsVisibleForOrigin(key: types.SearchField) {
  if (SearchFieldIsVisibleForDataset.has(key)) {
    return SearchFieldIsVisibleForDataset.get(key);
  }
  return true;
}

function getIsVisibleForPolicy(key: types.SearchField) {
  if (SearchFieldIsVisibleForPolicy.has(key)) {
    return SearchFieldIsVisibleForPolicy.get(key);
  }
  return true;
}

export function mapFields(f: FieldsConfig) {
  const map = (value: FieldsConfig[keyof FieldsConfig], key: types.SearchField) => ({
    key,
    ...value,
    data: {
      showForOrigin: getIsVisibleForOrigin(key),
      showForPolicy: getIsVisibleForPolicy(key),
      operators: defaultOperators,
      type: "string",
      isPluralOperators: false,
      ...value.data,
    } as Required<ConfigField["data"]>,
  });
  return mapValues(f, map) as any as Record<keyof FieldsConfig, ReturnType<typeof map>>;
}

export const baseStringOperators = [
  {
    key: FullOperatorEnum.OperatorIs,
  },
  {
    key: FullOperatorEnum.OperatorStartsWith,
  },
  {
    key: FullOperatorEnum.OperatorEndsWith,
  },
  {
    key: FullOperatorEnum.OperatorContains,
  },
  {
    key: FullOperatorEnum.OperatorMatchesRegexp,
  },
].flatMap((el) => [
  { ...el, negate: false, selector: "match_any" as const },
  { ...el, negate: true, selector: "match_any" as const },
]);

const existsOperators = [
  {
    key: FullOperatorEnum.OperatorExists,
    negate: false,
    selector: "match_any" as const,
  },
  {
    key: FullOperatorEnum.OperatorExists,
    negate: true,
    selector: "match_any" as const,
  },
];

export function getDefaultOperator(
  fieldName: FieldNames,
  extraFieldsMap: Record<string, ConfigField>
) {
  const operators = getOperators(fieldName, extraFieldsMap)!;
  if ([AllFieldNamesEnum.DatasetName, AllFieldNamesEnum.PolicyName].includes(fieldName as any)) {
    return operators.find((el: any) => el.key === FullOperatorEnum.OperatorIs)!;
  }
  return operators.find((el: any) => el.key === FullOperatorEnum.OperatorContains) || operators[0];
}

export type OnAddConditionProps = {
  fieldName: FieldNames;
  value?: string | string[];
  operator?: Operator;
};

export const CASE_SENSITIVE_FIELDS = ["md5_hash", "sha256_hash"];

export function getDefaultsForCondition(
  { fieldName, value, operator }: OnAddConditionProps,
  extraFieldsMap: Record<string, ConfigField> = {}
): types.QueryCondition {
  const values =
    value && Array.isArray(value) ? value.map((v) => ({ value: v })) : [{ value: value! }];
  operator = operator || getDefaultOperator(fieldName, extraFieldsMap);
  return {
    field_name: fieldName as types.SearchField,
    operator: operator!.key as types.Operator,
    negated: operator!.negate,
    selector: operator!.selector as types.Selector,
    values: values || getDefaultValue(fieldName, operator!.key, extraFieldsMap as any)!,
    case_sensitive: CASE_SENSITIVE_FIELDS.includes(fieldName as string),
  };
}

export function getDefaultValue(
  fieldName: FieldNames,
  operator: string,
  extraFieldsMap: typeof fieldsMap
) {
  const field = fieldsMap[fieldName] || extraFieldsMap?.[fieldName];
  if (!field) {
    throw new Error("unknown field");
  }
  if (operator === FullOperatorEnum.OperatorIsBetween) {
    return [{ value: "" }, { value: "" }];
  }
  const fieldTypes = getFieldTypes(Object.values(extraFieldsMap || {}));
  if (fieldTypes.string.includes(fieldName)) {
    return [{ value: "" }];
  }

  return undefined;
}

export const pathOperators: Operator[] = [
  ...baseStringOperators,
  {
    key: FullOperatorEnum.OperatorEqualsOrIsSubpath,
    negate: true,
    selector: "match_any",
  },
  {
    key: FullOperatorEnum.OperatorEqualsOrIsSubpath,
    negate: false,
    selector: "match_any",
  },
];

export const domainOperators: Operator[] = [
  ...baseStringOperators,
  {
    key: FullOperatorEnum.OperatorEqualsOrIsSubdomain,
    negate: true,
    selector: "match_any",
  },
  {
    key: FullOperatorEnum.OperatorEqualsOrIsSubdomain,
    negate: false,
    selector: "match_any",
  },
];

export const edmOperators: Operator[] = [
  {
    key: FullOperatorEnum.OperatorHasMultitag,
  },
].flatMap((el) => [
  { ...el, negate: false, selector: "match_any" },
  { ...el, negate: false, selector: "match_all" },
  { ...el, negate: true, selector: "match_any" },
  { ...el, negate: true, selector: "match_all" },
]);

export const contentAttributesOperators: Operator[] = [
  {
    key: FullOperatorEnum.OperatorHasMultitag,
  },
].flatMap((el) => [
  { ...el, negate: false, selector: "match_any" },
  { ...el, negate: false, selector: "match_all" },
  { ...el, negate: true, selector: "match_any" },
  { ...el, negate: true, selector: "match_all" },
]);

export const tagsOperators: Operator[] = [
  {
    key: FullOperatorEnum.OperatorHasTag,
  },
].flatMap((el) => [
  { ...el, negate: false, selector: "match_any" },
  { ...el, negate: false, selector: "match_all" },
  { ...el, negate: true, selector: "match_any" },
  { ...el, negate: true, selector: "match_all" },
]);

export const multiSelectOperators: Operator[] = [
  {
    key: FullOperatorEnum.OperatorIs,
    selector: "match_any",
    negate: false,
    addSelector: true,
    caseSensitive: true,
  },
];

export const enumOperators: Operator[] = [
  {
    key: FullOperatorEnum.OperatorIs,
    selector: "match_any",
    negate: false,
    addSelector: true,
  },
  {
    key: FullOperatorEnum.OperatorIs,
    selector: "match_any",
    negate: true,
    addSelector: true,
  },
];

// default operators for fields
const defaultOperators = baseStringOperators;

export const localUserGroupsOperators: Operator[] = [
  {
    key: FullOperatorEnum.OperatorIs,
  },
  {
    key: FullOperatorEnum.OperatorContains,
  },
  {
    key: FullOperatorEnum.OperatorMatchesRegexp,
  },
].flatMap((el) => [
  { ...el, negate: false, selector: "match_any" },
  { ...el, negate: true, selector: "match_any" },
]);

export const numericOperator: Operator[] = [
  {
    key: FullOperatorEnum.OperatorIsBetween,
  },
  {
    key: FullOperatorEnum.OperatorIsGreaterThan,
  },
  {
    key: FullOperatorEnum.OperatorIsEqualTo,
  },
].flatMap((el) => [
  { ...el, negate: false, selector: "match_any" },
  { ...el, negate: true, selector: "match_any" },
]);

// lower is higher
export const primaryFields = new Set([
  "location",
  "domain",
  "url",
  "email_account",
  "hostname",
  "app_name",
  "cloud_share_type",
  "cloud_app_account",
  "cloud_app",
  "local_user_name",
  "group_name",
  "data_size",
  "content_tags",
  "personal_info_counts",
  "extension",
  "path_basename",
  "path",
  ...(ENV.FEATURES.RO_USER_DIRECTORY_RULES ? ["user.risk_groups.id"] : []),
  ...(ENV.FEATURES.EDM_RULES_ENABLED ? ["edm_rules"] : []),
  "dataset_id",
  "dataset_name",
  "dataset_sensitivity",
  "policy_id",
  "policy_name",
  "policy_severity",
]);

export const autoCompleteOperators = [
  FullOperatorEnum.OperatorIs,
  FullOperatorEnum.OperatorContains,
  FullOperatorEnum.OperatorStartsWith,
  FullOperatorEnum.OperatorEndsWith,
  FullOperatorEnum.OperatorEqualsOrIsSubdomain,
  FullOperatorEnum.OperatorEqualsOrIsSubpath,
];
export const caseInsensitiveOperators = [FullOperatorEnum.OperatorHasText];

const baseDirectoryStringData: ConfigField["data"] = {
  group: "directory",
  operators: [...baseStringOperators, ...existsOperators],
  type: "string",
};

const riskOverviewAvailableFields: {
  [key in SearchFieldValue]?: Omit<ConfigField, "scope">;
} = {
  [types_SearchFieldEnum.SearchFieldZone]: {
    info: "Location zone (deprecated)",
    title: "Zone",
    data: {
      group: "location",
      type: "enum",
      options: [
        {
          value: "internal",
          label: "Internal",
        },
        {
          value: "external",
          label: "External",
        },
      ],
      operators: enumOperators,
    },
  },
  [types_SearchFieldEnum.SearchFieldDataSize]: {
    title: "Data size",
    info: "Size of transferred data",
    data: {
      group: "event",
      type: "number",
      operators: numericOperator,
    },
  },
  [types_SearchFieldEnum.SearchFieldFileSize]: {
    title: "File size",
    info: "Size of the file",
    data: {
      group: "location",
      type: "number",
      operators: numericOperator,
    },
  },
  [types_SearchFieldEnum.SearchFieldRemovableMediaSerialNo]: {
    title: "Removable media device ID",
    info: "Unique hardware ID of the removable media",
    data: {
      group: "location",
    },
  },
  [types_SearchFieldEnum.SearchFieldRemovableMediaProductID]: {
    title: "Removable media Product ID",
    info: "Removable Product ID is a 16-bit number assigned to specific USB device models by the manufacturer.",
    data: {
      group: "location",
    },
  },
  [types_SearchFieldEnum.SearchFieldRemovableMediaVendorID]: {
    title: "Removable media Vendor ID",
    info: "Removable Vendor ID is a 16-bit number assigned to USB device manufacturers by the USB Implementers Forum.",
    data: {
      group: "location",
    },
  },
  [types_SearchFieldEnum.SearchFieldLocationCategory]: {
    info: "Type of data location",
    title: "Location type",
    data: {
      group: "location",
      type: "enum",
      options: locationCategoryOptions,
      operators: enumOperators,
    },
  },
  [types_SearchFieldEnum.SearchFieldDomain]: {
    title: "Domain",
    info: "Website or email domain",
    data: {
      group: "location",
      operators: domainOperators,
    },

    icons: [getIcon(locations.website.value), getIcon(locations.mail.value)],
  },
  [types_SearchFieldEnum.SearchFieldURL]: {
    info: "Website or cloud app URL",
    title: "URL",
    data: {
      group: "location",
    },

    icon: getIcon(locations.website.value),
  },
  [types_SearchFieldEnum.SearchFieldBrowserPageTitle]: {
    info: "Title of page that initiated download or upload",
    title: "Website page title",
    data: {
      group: "location",
    },

    icon: getIcon(locations.website.value),
  },
  [types_SearchFieldEnum.SearchFieldEmailAddress]: {
    title: "Email address",
    info: "To, from, cc or bcc email address",
    data: {
      group: "location",
      operators: baseStringOperators,
    },

    icon: getIcon(locations.mail.value),
  },
  [types_SearchFieldEnum.SearchFieldCloudSharedRole]: {
    title: "Cloud access level",
    info: "Access level with which the file was shared",
    data: {
      group: "location",
      type: "enum",
      options: cloudAccessLevelOptions,
      operators: enumOperators,
    },
    icon: getIcon(locations.cloud_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldCloudAppAccount]: {
    title: "Cloud acting user",
    info: "Cloud acting user email address",
    data: {
      group: "location",
      operators: baseStringOperators,
    },
    icon: getIcon(locations.cloud_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldCloudSharedType]: {
    title: "Cloud scope",
    info: "Who can access the file",
    data: {
      group: "location",
      type: "enum",
      options: cloudSharedTypeCategoryOptions,
      operators: enumOperators,
    },
    icon: getIcon(locations.cloud_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldCloudDomain]: {
    info: "Cloud app domain",
    title: "Cloud domain",
    data: {
      group: "location",
      operators: baseStringOperators,
    },
    icon: getIcon(locations.website.value),
  },
  [types_SearchFieldEnum.SearchFieldCloudSharedWith]: {
    info: "Cloud app account email addresses that have been granted sharing access",
    title: "Cloud destination accounts",
    data: {
      group: "location",
      operators: baseStringOperators,
      isPluralOperators: true,
    },
    icon: getIcon(locations.cloud_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldCloudDestinationGroups]: {
    info: "Cloud app group name that has been granted sharing access",
    title: "Cloud destination groups",
    data: {
      group: "location",
      operators: baseStringOperators,
      isPluralOperators: true,
    },
    icon: getIcon(locations.cloud_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldCloudWorkspace]: {
    info: "Cloud app workspace name",
    title: "Cloud workspace",
    data: {
      group: "location",
      operators: baseStringOperators,
    },
    icon: getIcon(locations.cloud_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldCloudMessagingUsers]: {
    info: "Cloud messaging app source or destination username",
    title: "Cloud messaging users",
    data: {
      group: "location",
      operators: baseStringOperators,
      isPluralOperators: true,
    },
    icon: getIcon(locations.cloud_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldCloudMessagingGroups]: {
    info: "Cloud messaging app source or destination group name",
    title: "Cloud messaging groups",
    data: {
      group: "location",
      operators: baseStringOperators,
      isPluralOperators: true,
    },
    icon: getIcon(locations.cloud_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldCloudApp]: {
    info: "Name of cloud app connected to Cyberhaven through APIs",
    title: "Cloud app",
    data: {
      group: "location",
      type: "enum",
      operators: enumOperators,
      options: CLOUD_APP_TYPES,
    },

    icon: getIcon(locations.cloud_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldHostname]: {
    title: "Hostname",
    info: "Endpoint or shared folder hostname",
    data: {
      group: "location",
    },
    icons: [getIcon(locations.endpoint.value), getIcon(locations.share.value)],
  },
  [types_SearchFieldEnum.SearchFieldEndpointApplication]: {
    title: "Endpoint application",
    info: "Endpoint app that produced or accessed data",
    data: {
      group: "location",
    },
    icon: getIcon(locations.endpoint_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldAppMainWindowTitle]: {
    title: "Endpoint app main window title",
    info: "Main window title of the endpoint app accessing data",
    data: {
      group: "location",
    },

    icon: getIcon(locations.endpoint_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldEndpointAppCommandLine]: {
    title: "Endpoint app command line",
    info: "Command line of the endpoint app accessing data",
    data: {
      group: "location",
    },
    icon: getIcon(locations.endpoint_apps.value),
  },
  [types_SearchFieldEnum.SearchFieldFilePath]: {
    title: "File path",
    info: "A path of the file on an endpoint, in a shared folder or a cloud app",
    data: {
      operators: pathOperators,
      group: "location",
    },
  },
  [types_SearchFieldEnum.SearchFieldPrinterName]: {
    title: "Printer name",
    info: "Name of the printer",
    data: {
      group: "location",
    },
    icon: getIcon(locations.printer.value),
  },
  [types_SearchFieldEnum.SearchFieldRemovableMediaType]: {
    title: "Removable media type",
    info: "Type of the removable media",
    data: {
      group: "location",
      type: "enum",
      operators: enumOperators,
      options: MEDIA_CATEGORY_TYPES.filter((el) => el.value !== "*").sort(compareByProp("label")),
    },
    icon: getIcon(locations.removable_media.value),
  },
  [types_SearchFieldEnum.SearchFieldRemovableMediaName]: {
    title: "Removable media name",
    info: "Name of the removable media",
    data: {
      group: "location",
    },
    icon: getIcon(locations.removable_media.value),
  },
  [types_SearchFieldEnum.SearchFieldLocalUserName]: {
    title: "User name",
    info: "User who added or edited data in this location",
    data: {
      group: "user",
    },
  },
  [types_SearchFieldEnum.SearchFieldLocalUserGroups]: {
    title: "User groups",
    info: "Groups of the user who added or edited data in this location",
    data: {
      operators: localUserGroupsOperators,
      group: "user",
      isPluralOperators: true,
    },
  },
  [types_SearchFieldEnum.SearchFieldDocumentTags]: {
    title: "Document tags",
    info: "Tags in document metadata (e.g., RMS tags)",
    data: {
      type: "content_tags",
      operators: tagsOperators,
      group: "content",
      isPluralOperators: true,
    },
    requiredPermissions: ["file_tags__read"],
  },
  ...(ENV.FEATURES.EDM_RULES_ENABLED
    ? {
        [types_SearchFieldEnum.SearchFieldEDMAttributes]: {
          title: "Exact data match rules",
          info: "EDM rule that triggered the event",
          data: {
            type: "edm_rules",
            group: "content",
            operators: edmOperators,
            isPluralOperators: true,
          },
          requiredPermissions: ["edm_rules__read"],
        },
      }
    : {}),
  [types_SearchFieldEnum.SearchFieldContentAttributes]: {
    title: "Content attributes",
    info: "Attributes detected in data content",
    data: {
      type: "personal_info_counts",
      operators: contentAttributesOperators,
      group: "content",
      isPluralOperators: true,
    },
    requiredPermissions: ["dlp_rules__read"],
  },
  [types_SearchFieldEnum.SearchFieldFileType]: {
    title: "File type",
    info: "Type (extension) of the file",
    data: {
      type: "extension",
      operators: enumOperators,
      group: "content",
    },
  },
  [types_SearchFieldEnum.SearchFieldFileName]: {
    title: "File name",
    info: "Name of file (including extension)",
    data: {
      group: "content",
    },
  },
  [types_SearchFieldEnum.SearchFieldWebsiteCategory]: {
    title: "Website category(deprecated)",
    info: "Use location type instead",
    data: {
      group: "location",
      type: "enum",
      operators: enumOperators,
      options: CATEGORY_TYPES.filter((el: any) => el.value !== "*").sort(compareByProp("label")),
    },
  },
  [types_SearchFieldEnum.SearchFieldEmailSubject]: {
    title: "Email subject",
    info: "A subject line of an email",
    data: {
      group: "content",
    },
  },
  [types_SearchFieldEnum.SearchFieldMD5Hash]: {
    title: "MD5 Hash",
    info: "MD5 hash of data",
    data: {
      group: "content",
      operators: enumOperators,
    },
  },
  [types_SearchFieldEnum.SearchFieldSHA256Hash]: {
    title: "SHA256 Hash",
    info: "SHA256 hash of data",
    data: {
      group: "content",
      operators: enumOperators,
    },
  },
  [types_SearchFieldEnum.SearchFieldRepositoryName]: {
    title: "Repository Name",
    info: "Content repository name",
    data: {
      group: "content",
    },
  },
  [types_SearchFieldEnum.SearchFieldRepositoryOrganization]: {
    title: "Repository Organization",
    info: "Content repository organization",
    data: {
      group: "content",
    },
  },
  [types_SearchFieldEnum.SearchFieldEventType]: {
    info: "Action performed by the user",
    title: "Action",
    data: {
      group: "event",
      type: "event_type",
      operators: enumOperators,
      options: getUniqEvents(
        Object.entries(EventTypesMap)
          .map(([value, label]) => ({ label, value }))
          .sort(compareByProp("label")),
        (v) => v.value as any
      ),
    },
  },
};

const riskOverviewDirectoryAvailableFields: {
  [key in SearchFieldValue]?: Omit<ConfigField, "scope">;
} = {
  ...(ENV.FEATURES.RO_USER_DIRECTORY_RULES
    ? {
        [types_SearchFieldEnum.SearchFieldUserRiskGroups]: {
          title: "User risk groups",
          info: "Groups of users who added or edited data",
          data: {
            group: "user",
            type: "dynamic_enum",
            operators: multiSelectOperators,
            options: [{ value: true, label: "Loading..." }],
            isPluralOperators: true,
          },
          requiredPermissions: ["user_risk_group__read"],
        },
      }
    : {}),
};

const IRMAvailableFields: { [key in UserColumnField]?: Omit<ConfigField, "scope"> } = {
  [UserColumnField.Alias]: {
    title: "Endpoint Email",
    info: "Email address configured on endpoint or cloud service account",
    data: baseDirectoryStringData,
  },
  [UserColumnField.LocalUserName]: {
    title: "Endpoint user name",
    info: "User name reported by endpoint",
    data: baseDirectoryStringData,
    deprecationMessage:
      "Endpoint user name field is deprecated. Use manual user risk groups to select specific users",
  },
  [UserColumnField.MachineName]: {
    title: "Hostname",
    info: "Endpoint or shared folder hostname",
    data: baseDirectoryStringData,
    deprecationMessage:
      "Hostname field is deprecated. Use manual user risk groups to select specific users",
  },
  [UserColumnField.PrimaryEmail]: {
    title: "Primary Email",
    info: "Primary email address of the user",
    data: baseDirectoryStringData,
  },
  [UserColumnField.Emails]: {
    title: "Email Addresses",
    info: "Directory email addresses of the user",
    data: { ...baseDirectoryStringData, isPluralOperators: true },
  },
  [UserColumnField.FirstName]: {
    title: "First Name",
    info: "First name of the user",
    data: baseDirectoryStringData,
  },
  [UserColumnField.LastName]: {
    title: "Last Name",
    info: "Last name of the user",
    data: baseDirectoryStringData,
  },
  [UserColumnField.FullName]: {
    title: "Full Name",
    info: "Full name of the user",
    data: baseDirectoryStringData,
  },
  [UserColumnField.EmployeeStatus]: {
    title: "Employee Status",
    info: "Employee status of the user",
    data: baseDirectoryStringData,
  },
  [UserColumnField.Title]: {
    title: "Title",
    info: "User job title",
    data: baseDirectoryStringData,
  },
  [UserColumnField.TerminationDate]: {
    title: "Employee termination date",
    info: "Date of employee contract termination",
    data: { group: "directory", type: "date", operators: existsOperators },
  },
  [UserColumnField.Department]: {
    title: "Department",
    info: "Department the user is in",
    data: baseDirectoryStringData,
  },
  [UserColumnField.PrimaryAddressRegion]: {
    title: "Region",
    info: "Region the user is based in",
    data: baseDirectoryStringData,
  },
  [UserColumnField.PrimaryAddressCountry]: {
    title: "Country",
    info: "Country the user is based in",
    data: baseDirectoryStringData,
  },
  [UserColumnField.PrimaryAddressCity]: {
    title: "City",
    info: "City the user is based in",
    data: baseDirectoryStringData,
  },
  [UserColumnField.PrimaryAddressZipCode]: {
    title: "ZIP Code",
    info: "ZIP code the user is based in",
    data: baseDirectoryStringData,
  },
  [UserColumnField.PrimaryAddressStreetAddress]: {
    title: "Street Address",
    info: "Street address the user is based in",
    data: baseDirectoryStringData,
  },
  [UserColumnField.PhoneNumber]: {
    title: "Phone Number",
    info: "Phone number of the user",
    data: baseDirectoryStringData,
  },
  [UserColumnField.ManagerName]: {
    title: "Manager Name",
    info: "Name of the user's manager",
    data: baseDirectoryStringData,
  },
  [UserColumnField.ManagerEmail]: {
    title: "Manager Email",
    info: "Email address of the user's manager",
    data: baseDirectoryStringData,
  },
  [UserColumnField.UserGroups]: {
    title: "User Groups",
    info: "Directory user groups the user is in",
    data: { ...baseDirectoryStringData, isPluralOperators: true },
  },
  [UserColumnField.Remote]: {
    title: "Remote",
    info: "Whether the user is remote",
    data: {
      group: "directory",
      type: "boolean",
      operators: enumOperators,
      options: [
        { value: true, label: "True" },
        { value: false, label: "False" },
      ],
    },
  },
};

const mapValuesWithScope = (
  values: Record<string, Omit<ConfigField, "scope">>,
  ...scopes: ConfigFieldScope[]
) =>
  mapValues(values, (val) => ({
    ...val,
    scope: scopes,
  }));

const unmergedFieldSpecAvailableFields = [
  mapValuesWithScope(riskOverviewAvailableFields, ConfigFieldScope.RISK_OVERVIEW),
  mapValuesWithScope(IRMAvailableFields, ConfigFieldScope.IRM),
  mapValuesWithScope(
    riskOverviewDirectoryAvailableFields,
    ConfigFieldScope.RISK_OVERVIEW_DIRECTORY
  ),
] as FieldsConfig[];

const mergeScopes = (fields: FieldsConfig[]): FieldsConfig => {
  const result: FieldsConfig = fields[0];

  fields.shift();

  fields.forEach((field) => {
    const fieldEntries = Object.entries(field) as Array<[keyof FieldsConfig, ConfigField]>;
    fieldEntries.forEach(([key, value]) => {
      if (key in result) {
        result[key]?.scope?.push(...(value?.scope ?? []));
      } else {
        result[key] = value;
      }
    });
  });

  return result;
};

export const fieldSpecAvailableFields: FieldsConfig = mergeScopes(unmergedFieldSpecAvailableFields);

export const fieldsMap = mapFields(fieldSpecAvailableFields as Omit<FieldsConfig, "sensor_name">);

export const fieldsList = Object.values(fieldsMap);

export const fieldsMapKeys = Object.keys(fieldsMap);

export const fieldsListWithOptions = fieldsList.filter((field: any) => !!field.data.options);

export const getFieldTypes = (extraFields: ConfigField[] = []) => {
  const result = mapValues(
    groupBy(fieldsList.concat(extraFields as any), (v: any) => v.data.type),
    (v) => v.map((el: any) => el.key)
  );
  result.lists = (result.lists || []).concat("list_matchers");
  return result;
};

export type FieldNames = keyof typeof fieldsMap;
export type FieldValues = (typeof fieldsMap)[keyof typeof fieldsMap];

export function getFieldLabel(k: FieldNames) {
  // @ts-ignore
  return fieldsMap[k]?.title;
}
export function getFieldType(k: FieldNames) {
  // @ts-ignore
  return fieldsMap[k]?.data?.type;
}
export function getOperators(key: FieldNames, extranFieldsMap?: Record<string, ConfigField>) {
  // @ts-ignore
  return fieldsMap[key]?.data?.operators || extranFieldsMap[key]?.data?.operators;
}

export const getLabelForGroup = (k: FieldsGroups, isOrigin: boolean) => {
  if (isOrigin && k === "location") {
    return "Source";
  }
  return upperFirst(k);
};
